
import { trackPricingGuideSubmit } from '~/lib/linkedInTracker'

export default {
  props: {
    slice: { type: Object, required: true },
  },
  mounted() {
    this.initializeForm()
  },
  methods: {
    initializeForm() {
      this.stubJquery()
      const action = () => {
        const formId = this.slice.primary.form_id
        const portalId =
          this.slice.primary.hubspot_instance === 'Test' ? '4408376' : '4053376'

        if (process.browser)
          window.hbspt.forms.create({
            portalId,
            formId,
            css: '',
            cssRequired: '',
            target: `#hubspot-form-${formId}`,
            submitButtonClass: 'submit-btn cta-primary text-cta-primary mt-8',
            errorMessageClass: 'text-red-500 text-sm my-2',
            onFormSubmit: () => {
              if (this.slice.primary.form_name === 'pricing-guide') {
                trackPricingGuideSubmit()
              }

              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Hubspot form submitted',
                eventAction: this.slice.primary.form_name || 'hubspot form',
                eventLabel: window.location.href,
              })
              const [emailInput] = document.getElementsByName('email')
              const email = emailInput ? emailInput.value : null
              this.trackMixpanelEvent('Hubspot form submitted', {
                formName: this.slice.primary.form_name || 'hubspot form',
                email,
              })
            },
          })
      }

      if (process.browser && window.hbspt && window.hbspt.forms) {
        const delayToEnsureLastFormHasBeenDestroyed = 900
        return setTimeout(action, delayToEnsureLastFormHasBeenDestroyed)
      } else {
        const source = '//js.hsforms.net/forms/v2.js'
        return this.getScript(source, action)
      }
    },
    stubJquery() {
      window.jQuery =
        window.jQuery ||
        function (nodeOrSelector) {
          return typeof nodeOrSelector === 'string'
            ? document.querySelector(s)
            : nodeOrSelector
        }
    },
    getScript(source, callback) {
      if (process.browser) {
        let script = window.document.createElement('script')
        const priorScript = window.document.getElementsByTagName('script')[0]

        script.async = 1
        script.onload = script.onreadystatechange = (_, isAbort) => {
          if (
            isAbort ||
            !script.readyState ||
            /loaded|complete/.test(script.readyState)
          ) {
            script.onload = script.onreadystatechange = null
            script = undefined
            if (!isAbort) if (callback) callback()
          }
        }

        script.src = source
        priorScript.parentNode.insertBefore(script, priorScript)
      }
    },
  },
}
